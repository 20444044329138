import { EMapTripDirection, EMapTripType, ERoadblockType, Roadblock } from './../../../data/roadblock.data';
import {IHttpService, ILogService, IRootScopeService, IScope, IWindowService} from 'angular';
import ErrorService from '../../../services/error.service';
import RestService from '../../../services/rest.service';
import {SettingsMode} from '../../modals/misc/settings.modal/settings.modal';
import {AlarmObjectsSimpleResponse, DocumentTag, ObjectDocument, ObjectDocumentsPage} from "../../../data/objects.data";
import PrivilegeService from '../../../services/privilege.service';
import {RolePrivilege} from '../../../data/privileges.enum';
import angular = require("angular");

'use strict';

require('./objects.view.component.css');

/* @ngInject */
export default class ObjectsController {
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public dataService: any;
  public $translate: any;
  public $uibModal: any;
  public $window: IWindowService;
  public $http: IHttpService;
  public $state: any;
  public FileUploader: any;
  public restService: RestService;
  public errorService: ErrorService;
  public Notification: any;
  public helperService: any;
  public roadblockTypes: any;
  public filteredRoadblockTypes: any;
  public roadblockStates: any;
  public filteredRoadblockStates: any;
  public listeners: any;
  public roadblockSettings: any;
  public hasObjects: boolean;
  public maxObjectsReached: boolean;
  public hasRoadblocks: boolean;
  public assets: any[];
  public pois: any[];
  public allStates: any;
  public filteredStates: any;
  public allRiskLevels: any;
  public filteredRiskLevel: any;
  public filterSubObjects: any;
  public isUploading: boolean;
  public isSendingRoadblocks: boolean;
  public baseUrl: string;
  public authHeader: any;
  public uploader: any;
  public isFirstTimeLoading: boolean;
  public dataObjects: AlarmObjectsSimpleResponse;
  public filter: any;
  public limit: number;
  public limitObjectDocuments: number;
  public account: any;
  public filteredLst: any[];
  public response: any;
  public tmp: any;
  public filteredRoadblocks: any;
  public roadblocks: any;
  public isLoading: boolean;
  public isLoadingImageAssets: boolean;
  public isLoadingPOIs: boolean;
  public hasUserDefinedFilter: boolean;
  public transmitingToAM4: boolean;
  public activeTabIndex: number;
  public currentPage: number = 0;


  public jumpToDate: Date;
  public isDateSearch = false;
  public docFilter = {
    searchFilter: '',
    searchTags: [],
  };

  private csvUploader: any;

  private sort: string = "name";
  private descending: boolean = false;
  private currentObjectDocumentsPage: number = 1;

  public allTags: DocumentTag[];
  private objectDocumentsList: ObjectDocumentsPage;
  private globalObjectDocumentsList: ObjectDocument[];


  constructor($rootScope, $scope, $log, $window, $state, $http, dataService, $translate, $uibModal, FileUploader, restService, errorService, Notification, helperService,
              public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$log.debug('ObjectsController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.dataService = dataService;
    this.$translate = $translate;
    this.$uibModal = $uibModal;
    this.$window = $window;
    this.$http = $http;
    this.$state = $state;
    this.FileUploader = FileUploader;
    this.restService = restService;
    this.errorService = errorService;
    this.Notification = Notification;
    this.helperService = helperService;
    this.roadblockTypes = this.dataService.getRoadblockTypes();
    this.filteredRoadblockTypes = [];
    this.roadblockStates = this.dataService.getRoadblockStates();
    this.filteredRoadblockStates = [];
    this.listeners = [];

    //Settings
    this.roadblockSettings = {
      filteredRoadblockTypes: [],
      filteredRoadblockStates: []
    };

    if (this.helperService.getFromStorage('roadblock_settings', false)) {
      this.roadblockSettings = this.helperService.getFromStorage('roadblock_settings', this.roadblockSettings);
    }


    this.hasObjects = false;
    this.maxObjectsReached = false;
    this.hasRoadblocks = false;
    this.assets = [];
    this.pois = [];
    // Filter Data
    this.allStates = ['DRAFT', 'RELEASED', 'IN_PROGRESS', 'DEPRECATED'];
    this.filteredStates = ['DRAFT', 'RELEASED', 'IN_PROGRESS', 'DEPRECATED'];
    this.allRiskLevels = ['LOW', 'NORMAL', 'HIGH', 'TEMP_HIGH'];
    this.filteredRiskLevel = ['LOW', 'NORMAL', 'HIGH', 'TEMP_HIGH'];
    this.filterSubObjects = true;
    this.isUploading = false;
    this.isSendingRoadblocks = false;
    this.baseUrl = this.restService.getBaseUrl();
    this.authHeader = encodeURI(this.restService.getAuthHeader());
    this.uploader = this.restService.createImageAssetUploader();

    this.isFirstTimeLoading = true;

    this.dataObjects = {
      alarmObjects:[],
      currentPage: 0,
      hasNext:false,
      hasPrevious:false,
      addAllowed:true
    };
    this.filter = {
      searchFilter: '',
      queryFilter: ''
    };
    this.limit = 50;
    this.limitObjectDocuments = 50;


    this.initListeners();
    if (this.dataService.hasAccount()) {
      this.init();
    }

    // Create CSV uploader
    this.csvUploader = this.restService.createCSVUploader('/objects/upload');
    this.csvUploader.onAfterAddingFile = () => {
      this.isLoading = true;
      this.csvUploader.uploadAll();
    }
    this.csvUploader.onCompleteAll = () => {
      this.isLoading = false;
      this.pageChanged();
    };

    this.csvUploader.onSuccessItem = (item, response, status, headers) => {
      this.$translate(['OBJECTS.IMPORT_TITLE']).then((translations) => {
        this.Notification.success({
          message: response.message,
          title: translations['OBJECTS.IMPORT_TITLE']
        });
      });

    };

    this.csvUploader.onErrorItem = (item, response, status, headers) => {
      this.$translate(['OBJECTS.IMPORT_TITLE']).then((translations) => {
        this.Notification.warning({
          message: response.message,
          title: translations['OBJECTS.IMPORT_TITLE']
        });
      });

    };
  }

  addObjectsAllowed(){
   return this.dataObjects.addAllowed;
  }

  init() {

    if (!this.privilegeService.has(RolePrivilege.Objects)) {
      this.$state.go('main.' + this.dataService.selectPageForPrivilege());
      return;
    }

    this.account = this.dataService.getAccount();
    if (this.privilegeService.has(RolePrivilege.Objects_Database)) {
      this.pageChanged();
    }
  };

  /**
   * Filter object table
   * @param {} type
   * @param {*} val
   */
  doObjectFiltering(type, val) {
    var lst = undefined;
    if (type === 'RISK') {
      lst = this.filteredRiskLevel;
    } else if (type === 'STATE') {
      lst = this.filteredStates;
    }
    if (angular.isUndefined(lst)) {
      return;
    }
    if (lst.indexOf(val) !== -1) {
      // Not yet filtered
      lst.splice(lst.indexOf(val), 1);
      this.$log.info('Removed from ' + type + ' filter: ' + val);
      this.$log.debug('Filter now contains [' + lst.toString() + ']');
    } else {
      lst.push(val);
      this.$log.info('Added to ' + type + ' filter: ' + val);
      this.$log.debug('Filter now contains [' + lst.toString() + ']');
    }
    //this.filterList();
    this.onSearchFilterChanged();
  };

  /**
   * Returns true if given element is filtered
   * @param {} type
   * @param {*} val
   */
  isFilteredObject(type, val, event) {
    var lst = undefined;
    if (type === 'RISK') {
      lst = this.filteredRiskLevel;
    } else if (type === 'STATE') {
      lst = this.filteredStates;
    }
    if (angular.isUndefined(lst)) {
      return true;
    }
    return lst.indexOf(val) !== -1;
  }



  /**
   * Filter roadblock table
   * @param {} type
   * @param {*} val
   */
  doRoadblockFiltering(type, val) {
    var lst = undefined;
    if (type === 'TYPE') {
      lst = this.roadblockSettings.filteredRoadblockTypes;
    } else if (type === 'STATE') {
      lst = this.roadblockSettings.filteredRoadblockStates;
    }
    if (angular.isUndefined(lst)) {
      return;
    }
    if (lst.indexOf(val) === -1) {
      // Not yet filtered
      lst.push(val);
      this.$log.info('Roadblock Filtered ' + type + ': ' + val);
    } else {
      lst.splice(lst.indexOf(val), 1);
      this.$log.info('Roadblock Unfiltered ' + type + ': ' + val);
    }

    this.filterRoadblockList()
  };

  /**
   * Returns true if given element is filtered
   * @param {} type
   * @param {*} val
   */
  isNotFilteredRoadblock(type, val) {
    var lst = undefined;
    if (type === 'TYPE') {
      lst = this.roadblockSettings.filteredRoadblockTypes;
    } else if (type === 'STATE') {
      lst = this.roadblockSettings.filteredRoadblockStates;
    }
    if (angular.isUndefined(lst)) {
      return true;
    }
    return lst.indexOf(val) === -1;
  }

  /**
   * Filter list
   */
  filterList() {
    this.filteredLst = [];
    for (var i = 0; i < this.response.data.length; i++) {
      var alarmObject = this.response.data[i];
      // Check filtered state
      if (this.filteredStates.includes(alarmObject.state)) {
        //filtered
        continue;
      }
      // Check filtered state
      if (this.filteredRiskLevel.includes(alarmObject.level)) {
        //filtered
        continue;
      }

      if (this.filterSubObjects && alarmObject.subType === 'SUB') {
        //filtered sub object
        continue;
      }
      this.filteredLst.push(alarmObject);
    }
    this.tmp.totalElements = this.filteredLst.length;
    this.pageChanged();
  };

  /**
   * Filter list
   */
  filterRoadblockList() {
    this.filteredRoadblocks = [];
    for (var i = 0; i < this.roadblocks.length; i++) {
      var roadblock = this.roadblocks[i];
      // Check filtered state

      var status = this.helperService.getRoadblockState(roadblock);

      if (this.roadblockSettings.filteredRoadblockStates.includes(status)) {
        //filtered
        continue;
      }
      // Check filtered state
      if (this.roadblockSettings.filteredRoadblockTypes.includes(roadblock.type)) {
        //filtered
        continue;
      }

      this.filteredRoadblocks.push(roadblock);

    }
    this.helperService.saveInStorage('roadblock_settings', this.roadblockSettings);
  };

  /**
    Opens email to admin popup
  */
  openSettings() {
    this.$uibModal.open({
      template: require('../../modals/misc/settings.modal/settings.modal.html'),
      controller: 'SettingsInstanceModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        settingsTab: () => {
          return SettingsMode.PASSWORD;
        }
      }
    });
  };

  /**
   * Load roadblocks from server
   */
  updateRoadblocks() {
    if (!this.account) return;
    this.isLoading = true;

    this.dataService.getAllRoadblocks(true, (roadblocks) => {
      this.roadblocks = roadblocks;
      this.$log.info('Roadblocks: ' + roadblocks.length);
      this.isLoading = false;
      this.hasRoadblocks = roadblocks.length > 0;
      this.filterRoadblockList();
      this.$scope.$applyAsync();

    }, (response) => {
      //Error occured
      this.$log.error(response);
      this.isLoading = false;
      this.hasRoadblocks = false;
    });
  };

  /**
   * Update image assets
   */
  updateImageAssets() {
    if (!this.account) return;
    this.isLoadingImageAssets = true;
    this.dataService.getAllImageAssets(true, (assets) => {
      this.assets = assets;
      this.$log.info('Image Assets: ' + assets.length);
      this.isLoadingImageAssets = false;
    }, (response) => {
      //Error occured
      this.$log.error(response);
      this.isLoadingImageAssets = false;
    });
  };

  hasNoDocs() {
    return this.docFilter.searchFilter == '' && this.docFilter.searchTags.length === 0 &&
      (this.objectDocumentsList == null || this.objectDocumentsList.totalElements === 0) &&
      (this.globalObjectDocumentsList == null || this.globalObjectDocumentsList.length === 0)
  }

  resetSearchDocFilter() {
    this.docFilter.searchFilter = '';
    this.pageObjectDocumentsChanged();
  }

  loadObjectDocumentTagsAll() {
    this.restService.getObjectDocumentTagsAll().then(response => {
      this.allTags = response.tags;
    }).catch(error => {
      this.$log.error(error);
    });
  }

  doDocsFiltering(tag: DocumentTag) {
    if (this.docFilter.searchTags.includes(tag)) {
      this.docFilter.searchTags.splice(this.docFilter.searchTags.indexOf(tag), 1)
    } else {
      this.docFilter.searchTags.push(tag);
    }
    this.pageObjectDocumentsChanged();
  }

  isNotFilteredDocs(tag: DocumentTag) {
    return this.docFilter.searchTags.includes(tag);
  }

  getTagsFilter() {
    return this.docFilter.searchTags.map((x) => x.id).join(";");
  }

  public downloadDocument(id: string) {
    const auth = this.$http.defaults.headers.common.Authorization;
    const url = `${this.restService.getBaseUrl()}/objects/document/${id}/download?Authorization=${auth}`;
    this.$window.open(url, '_blank')
  }

  pageObjectDocumentsChanged() {
    this.isLoading = true;
    if (!this.currentObjectDocumentsPage) {
      this.currentObjectDocumentsPage = 1;
    }

    this.restService.getObjectDocuments(this.currentObjectDocumentsPage - 1, this.limit, this.getTagsFilter(), this.docFilter.searchFilter).then(response => {
      this.objectDocumentsList = response.documents;
      this.globalObjectDocumentsList = response.globalDocuments;
      this.currentObjectDocumentsPage = this.objectDocumentsList.number + 1;
    }).catch(error => {
      this.$log.error(error);
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  };


  deleteDocument(id: string) {
    if (!this.privilegeService.has(RolePrivilege.Objects_ObjectDocument_Delete)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.restService.deleteObjectDocuments(id).catch(error => {
              this.$log.error(error);
            }).finally(() => {
              this.$scope.$applyAsync();
              this.pageObjectDocumentsChanged();
            });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  };


  editDocument(doc?: ObjectDocument) {
    if (!this.privilegeService.has(RolePrivilege.Objects_ObjectDocument_Edit)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../modals/objects/edit.document.modal/edit.document.modal.html'),
      controller: 'EditDocumentModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.pageObjectDocumentsChanged();
          };
        },
        objectDocument: () => {
          return doc;
        }
      }
    });
  };

  openTags() {
    if (!this.privilegeService.has(RolePrivilege.Objects_ObjectDocument_Tag)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../modals/objects/document.tag.modal/document.tag.modal.html'),
      controller: 'DocumentTagModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.updateDocuments();
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  }

  updateDocuments() {
    this.loadObjectDocumentTagsAll();
    this.pageObjectDocumentsChanged();
  }

  /**
   * Notify the hydrants component that a reload is neccessary
   */
  updateHydrants() {
    if (!this.account) return;
    setTimeout(() => {
      // Ugly, but we need a timeout do prevent emmiting the event too fast
      this.$rootScope.$emit('layers.reload');
    }, 500);
  };

  /**
   * Load POIs from server
   */
  updatePOIs() {
    if (!this.account) return;
    this.isLoadingPOIs = true;
    this.dataService.getAllPOIs(true, (pois) => {
      this.pois = pois;
      this.$log.info('POIs: ' + pois.length);
      this.isLoadingPOIs = false;
    }, (response) => {
      //Error occured
      this.$log.error(response);
      this.isLoadingPOIs = false;
    });
  };

  /**
   * Trigger update map event
   */
  updateLargeMap() {
    this.$rootScope.$emit('alarm.object.large.map.update');
  }

  /**
   * Add a new POI
   */
  addPOI() {
    this.$uibModal.open({
      template: require('../../modals/objects/add.object.modal/add.object.modal.html'),
      controller: 'AddAlarmObjectCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (name) => {
            this.dataService.addPOI(name, undefined, (poi) => {
              // Open new
              this.$uibModal.open({
                template: require('../../modals/objects/edit.poi.modal/edit.poi.modal.html'),
                controller: 'EditPOICtrl',
                controllerAs: 'ctrl',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  poi: () => {
                    return poi;
                  },
                  alarmObject: () => {
                    // Not assigned to any alarm object
                    return undefined;
                  },
                  editable: () => {
                    return true;
                  }
                }
              });
            }, (response) => {
              //Error occured
              this.$log.error(response);
            });
          }
        }
      }
    });
  };

  /**
   * Add a new alarm object
   */
  addAlarmObject() {
    this.$uibModal.open({
      template: require('../../modals/objects/add.object.modal/add.object.modal.html'),
      controller: 'AddAlarmObjectCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (name) => {
            this.isLoading = true;
            this.dataService.addAlarmObject(name, (alarmObject) => {
              this.pageChanged();
              this.isLoading = false;

              // Open new
              this.$uibModal.open({
                template: require('../../modals/objects/edit.alarm.object.modal/edit.alarm.object.modal.html'),
                controller: 'EditAlarmObjectCtrl',
                controllerAs: 'ctrl',
                backdrop: 'static',
                size: 'hu',
                resolve: {
                  alarmObject: () => {
                    return alarmObject;
                  },
                  alarmObjectId: () => {
                    return alarmObject.id;
                  }
                }
              });
            }, (response) => {
              //Error occured
              this.$log.error(response);
              this.isLoading = false;
            });

          }
        }
      }
    });
  };

  /**
   * Add a new roadblock
   */
  addRoadblock() {

    if (!this.privilegeService.has(RolePrivilege.Objects_Roadblocks_Edit)) {
      return;
    }
    // Open new created
    this.$uibModal.open({
      template: require('../../modals/objects/edit.roadblock.modal/edit.roadblock.modal.html'),
      controller: 'EditRoadblockCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        okFunction: () => {
          return () => {
            this.updateRoadblocks();
          }
        },
        roadblock: () => {
          return {
            type: ERoadblockType.ROAD_WORKS,
            mapTripDirection: EMapTripDirection.BOTH,
            mapTripType: EMapTripType.AVOID,
            routeShape: [],
            from: new Date().toISOString()
          } as Roadblock;
        }
      }
    });
    /*
    this.$uibModal.open({
      template: require('../../modals/objects/add.object.modal/add.object.modal.html'),
      controller: 'AddAlarmObjectCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (name: string) => {
            this.isLoading = true;
            this.dataService.addRoadblock(name, (roadblock) => {
              this.isLoading = false;
              this.filteredRoadblocks.push(roadblock);


            }, (response) => {
              //Error occured
              this.$log.error(response);
              this.isLoading = false;
            });
          }
        }
      }
    });*/
  }

  /**
   * resend roadblocks
   */
  resendRoadblocks() {

    this.isSendingRoadblocks = true;
    this.dataService.resendRoadblocks((result) => {
      this.isSendingRoadblocks = false;

      if (result.nbrOfReachedAM4s === 0) {
        // No AM4s reached
        this.$translate(['ROADBLOCKS.RESEND_WARNING_TITLE', 'ROADBLOCKS.RESEND_WARNING_MESSAGE']).then((translations) => {
          this.Notification.warning({
            message: translations['ROADBLOCKS.RESEND_WARNING_MESSAGE'],
            title: translations['ROADBLOCKS.RESEND_WARNING_TITLE']
          });
        });
      } else {
        this.$translate(['ROADBLOCKS.RESEND_SUCCESS_TITLE', 'ROADBLOCKS.RESEND_SUCCESS_MESSAGE']).then((translations) => {
          this.Notification.success({
            message: translations['ROADBLOCKS.RESEND_SUCCESS_MESSAGE'],
            title: translations['ROADBLOCKS.RESEND_SUCCESS_TITLE']
          });
        });
      }


    }, (response) => {
      //Error occured
      this.$log.error(response);
      this.isSendingRoadblocks = false;
    });
  };




  /**
   * Add a new image asset
   */
  addImageAsset() {
    this.$log.info('Queue: ' + this.uploader.queue.length);
    if (this.uploader.queue.length === 0) {
      return;
    }
    this.isUploading = true;

    this.uploader.onBeforeUploadItem = (item) => {
      //Change upload path
      item.url = item.url + '?name=' + encodeURI(item.file.name);
    };

    //Success
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.$log.info('Upload completed: ' + status);
      this.$log.info(response);
      this.isUploading = false;
      this.assets.push(response);
    };
    //Error
    this.uploader.onErrorItem = (item, response, status) => {
      this.$log.error('Upload failed: ' + status);
      this.$log.error(response);
      this.isUploading = false;
      this.errorService.notifyWithText(response.message);
    };
    this.uploader.uploadAll();
  };


  /**
    Search for specific alarm object
  */
  onSearchFilterChanged() {
    this.currentPage = 0;
    this.$rootScope.$emit('search.objects');
  };

  /**
   * Search for objects
   */
  pageChanged() {

    //Load alarm for user filtered by search string
    this.isLoading = true;

    var filterRisk = this.filteredRiskLevel.toString();
    var filterState = this.filteredStates.toString();

    this.hasUserDefinedFilter = this.filter.searchFilter !== '' || this.filteredRiskLevel.length < 4 || this.filteredStates.length < 4;
    this.filter.queryFilter = filterRisk.concat(';', filterState);

    this.dataService.searchForAlarmObject(this.currentPage, this.limit, this.filter.searchFilter, this.filter.queryFilter, this.sort, this.descending,
      (response) => {
        this.isFirstTimeLoading = false;
        this.isLoading = false;
        this.dataObjects = response;
        this.dataObjects.currentPage = this.dataObjects.currentPage + 1
        this.hasObjects = this.dataObjects.alarmObjects.length !== 0; // always needs to be true
        this.$scope.$applyAsync();
      },
        (response) => {
          //Error occured
          this.isLoading = false;
          this.$log.error(response);
        });

  }

  /**
    Reset the search filter
  */
  resetSearchFilter() {
    this.filter.searchFilter = '';
    this.filter.queryFilter = '';
    this.currentPage = 0;
    this.pageChanged();
  };

  /**
   * Init event listeners
   */
  initListeners() {
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', () => {
      //Init controller
      this.init();
    }));

    //Wait for object updated
    this.listeners.push(this.$rootScope.$on('objects.updated', (event, data) => {
      //filter list
      if (this.privilegeService.has(RolePrivilege.Objects_Database)) {
        this.pageChanged();
      }
    }));

    //Wait for object updated
    this.listeners.push(this.$rootScope.$on('roadblocks.updated', (event, data) => {
      //filter list
      this.roadblocks = data;
      this.filterRoadblockList();
    }));

    //Wait for object deleted
    this.listeners.push(this.$rootScope.$on('objects.deleted', (event, data) => {
      if (this.privilegeService.has(RolePrivilege.Objects_Database)) {
        this.pageChanged();
      }

    }));

    // Search debouncer
    (this.$rootScope as any).$eventToObservable('search.objects').debounce(500).subscribe((val) => {
      if (this.privilegeService.has(RolePrivilege.Objects_Database)) {
        this.pageChanged();
      }
    });



    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('delete.account', () => {
      this.account = undefined;
    }));


    this.listeners.push(this.$rootScope.$on('tab.change.object', (event, data) => {
      if (data >= 0 && data <= 5) {
        this.activeTabIndex = data;
        switch (data) {
          case 1:
            this.updateRoadblocks();
            break;
          case 2:
            this.updateHydrants();
            break;
          case 3:
            this.updatePOIs();
            break;
          case 4:
            this.updateImageAssets();
            break;
          case 5:
            this.updateDocuments();
            break;
          default:
            break;
        }
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  nextPage() {
    this.currentPage++;
    this.pageChanged();
  }

  previousPage() {
    if (this.currentPage === 0) return;
    this.currentPage--;
    this.pageChanged();
  }

  toggleSort(field: string) {
    if(this.sort === field) {
      if(!this.descending) {
        this.descending = true;
      } else {
        this.sort = "name";
        this.descending = false;
      }
    } else {
      this.sort = field;
      this.descending = false;
    }
    this.currentPage = 0;
    this.pageChanged();
  }

  /**
   * Select a alarm object
   * @param {*} alarmObject
   */
  selectAlarmobject(alarmObject) {
    if (!this.privilegeService.has(RolePrivilege.Objects_Database)) {
      return;
    }

    const modalInstance = this.$uibModal.open({
      template: require('../../modals/objects/edit.alarm.object.modal/edit.alarm.object.modal.html'),
      controller: 'EditAlarmObjectCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'hu',
      resolve: {
        alarmObject: () => {
          return alarmObject;
        },
        alarmObjectId: () => {
          return alarmObject.id;
        }
      }
    });
    modalInstance.result.then(() => this.pageChanged());
  }

  /**
   * Select a roadblock
   * @param {*}
   */
  selectRoadblock(roadblock) {
    this.$uibModal.open({
      template: require('../../modals/objects/edit.roadblock.modal/edit.roadblock.modal.html'),
      controller: 'EditRoadblockCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        okFunction: () => {
          return () => {
            this.updateRoadblocks();
          }
        },
        roadblock: () => {
          return roadblock;
        }
      }
    });
  };

  /**
   * Select a poi
   * @param {*}
   */
  selectPOI(poi) {
    this.$uibModal.open({
      template: require('../../modals/objects/edit.poi.modal/edit.poi.modal.html'),
      controller: 'EditPOICtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        poi: () => {
          return poi;
        },
        alarmObject: () => {
          // Not assigned to any alarm object
          return undefined;
        },
        editable: () => {
          return true;
        }
      }
    });
  }

  /**
   * Export addressbook as pdf
   */
  exportRoadblocksAsPdf(range) {
    this.$window.open(
      this.restService.getBaseUrl() +
      '/objects/roadblocks/export?range=' + range + '&Authorization=' +
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );
  };

  exportRoadblocksAsCSV() {
    this.$window.open(
      this.restService.getBaseUrl() +
      '/objects/roadblocks/export/csv?Authorization=' +
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );
  }

  /**
   * Export addressbook as pdf
   */
  exportEventsAsPdf(range) {
    this.$window.open(
      this.restService.getBaseUrl() +
      '/objects/events/export?range=' + range + '&Authorization=' +
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );
  };

  importRoadblocks() {
    var modalInstance = this.$uibModal.open({
      template: require('../../modals/objects/import.roadblocks.modal/import.roadblocks.modal.html'),
      controller: 'ImportRoadblocksModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        simple: () => {
          return "/objects/roadblocks/import/csv";
        },
        uploader: () => {
          let fileUploader = new this.FileUploader();
          return fileUploader
        },
        title: () => {
          return "TITLE_CSV";
        },
      }
    });
    modalInstance.result.then(() => {
      this.updateRoadblocks();
    });

  }

  importRoadblocksWDX3() {
    var modalInstance = this.$uibModal.open({
      template: require('../../modals/objects/import.roadblocks.modal/import.roadblocks.modal.html'),
      controller: 'ImportRoadblocksModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        simple: () => {
          return "/objects/roadblocks/import/csvWDX3";
        },
        uploader: () => {
          let fileUploader = new this.FileUploader();
          return fileUploader
        },
        title: () => {
          return "TITLE_CSV_WDX3";
        },
      }
    });
    modalInstance.result.then(() => {
      this.updateRoadblocks();
    });

  }

  /**
   * Transmit to AM4
   */
  transmitToAM4() {
    this.transmitingToAM4 = true;
    this.restService.transmitMarkerToAM4((result) => {
      // OK
      this.transmitingToAM4 = false;

      if (result.nbrOfReachedAM4s === 0) {
        // No AM4s reached
        this.$translate(['ROADBLOCKS.RESEND_WARNING_TITLE', 'POI.RESEND_WARNING_MESSAGE']).then((translations) => {
          this.Notification.warning({
            message: translations['POI.RESEND_WARNING_MESSAGE'],
            title: translations['ROADBLOCKS.RESEND_WARNING_TITLE']
          });
        });
      } else {
        this.$translate(['ROADBLOCKS.RESEND_SUCCESS_TITLE', 'POI.RESEND_SUCCESS_MESSAGE']).then((translations) => {
          this.Notification.success({
            message: translations['POI.RESEND_SUCCESS_MESSAGE'],
            title: translations['ROADBLOCKS.RESEND_SUCCESS_TITLE']
          });
        });
      }

    }, () => {
      // Error
      this.transmitingToAM4 = false;
    });
  }

  public exportExaminations() {
    const auth = this.$http.defaults.headers.common.Authorization;
    const url = `${this.restService.getBaseUrl()}/examination/export/asCSV?Authorization=${auth}`;
    this.$window.open(url, '_blank')
  }

}
