'use strict';

import { ILogService, IScope, ITimeoutService } from 'angular';
import angular = require('angular');
import { AlarmData } from '../../../../data/alarm.data';
import MapService from '../../../../services/map.service';
import * as L from 'leaflet';
import HelperService from '../../../../services/helper.service';


//alarmMap
export default class AlarmMapComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.map.component.html');
    this.scope = {
      alarm: '='
    };
    this.controller = AlarmMapComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmMapComponentController {
  public $timeout: ITimeoutService;
  public $scope: IScope;
  public hasCoordinates = false;
  public hasAddress = false;
  public $log: ILogService;
  public map: L.map;
  public alarm: AlarmData;
  public mapService: MapService;

  constructor($scope: IScope, $timeout: ITimeoutService, private $translate, $log: ILogService, mapService: MapService, private helperService: HelperService) {
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$log = $log;
    this.mapService = mapService;
    L.Icon.Default.imagePath = '/img/static';



    // Create map
    this.map = L.map('alarm-modal-map', {
      worldCopyJump: true
    });

    const layers = this.mapService.getBaseLayers();
    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    this.map.on('baselayerchange', function (e) {
      mapService.saveLayer(e.name);
    });


    $scope.$watch('ctrl.alarm', (oldData, newData: AlarmData) => {
      if (newData) {
        this.alarm = newData;
        this.updateMap();
      }
    });
  }

  /**
   * Updates the build in map after selecting the tab header
   * */
  updateMap() {
    if (angular.isUndefined(this.alarm)) {
      return;
    }
    this.hasAddress = angular.isDefined(this.alarm.parameters['location_dest']);
    this.hasCoordinates = angular.isDefined(this.alarm.parameters['lat']) && angular.isDefined(this.alarm.parameters['lng']);


    //Update the map
    if (this.hasCoordinates) {
      const latLngAlarmMarker = L.latLng(parseFloat(this.alarm.parameters['lat']), parseFloat(this.alarm.parameters['lng']));
      var emoji = '';
      if (this.alarm.parameters['keyword_category']) {
        emoji = this.alarm.parameters['keyword_category'];
        if (emoji.length > 3) {
          // If emoji is longer than three chars, it is probably not an emoji
          emoji = '';
        }
      }
      const alarmMarker = L.marker(latLngAlarmMarker, { icon: this.helperService.createDivIconWithFontAwesomeIcon('fas fa-fire', emoji, this.alarm.parameters['keyword_color']) });
      alarmMarker.addTo(this.map);
      if (this.alarm.parameters['location_dest']) {
        alarmMarker.bindPopup(this.alarm.parameters['location_dest']);
      }

      const markers: L.latLng[] = [];
      markers.push(latLngAlarmMarker);

      // Check for routing coordinates
      if (this.alarm.parameters['lat_routing']) {
        const latLngRoutingMarker = L.latLng(parseFloat(this.alarm.parameters['lat_routing']), parseFloat(this.alarm.parameters['lng_routing']));
        const routingMarker = L.marker(latLngRoutingMarker, { icon: this.helperService.createDivIconWithFontAwesomeIcon('fas fa-directions','', '#015958') });
        routingMarker.addTo(this.map);
        markers.push(latLngRoutingMarker);

        this.$translate('ALARM.MAP_ROUTING').then((translation) => {
          routingMarker.bindPopup(translation);
        });

      }

      // Check for foreign coordinates
      if (this.alarm.parameters['lat_foreign']) {
        const latLngForeignMarker = L.latLng(parseFloat(this.alarm.parameters['lat_foreign']), parseFloat(this.alarm.parameters['lng_foreign']));
        const foreignMarker = L.marker(latLngForeignMarker, { icon: this.helperService.createDivIconWithFontAwesomeIcon('fas fa-phone-alt','', '#3981BF') });
        foreignMarker.addTo(this.map);
        markers.push(latLngForeignMarker);
        this.$translate('ALARM.MAP_FOREIGN').then((translation) => {
          foreignMarker.bindPopup(translation);
        });
      }

      this.map.fitBounds(L.latLngBounds(markers), {maxZoom: 16});

      this.$timeout(() => {
        this.map.invalidateSize(true);
      }, 300);
    }
  };

  getURL() {
    return encodeURIComponent(this.alarm.parameters['location_dest']);
  };
}
