'use strict';

import { ILocationService, ILogService, IRootScopeService, IScope } from "angular";
import { RolePrivilege } from "../../../data/privileges.enum";
import PrivilegeService from "../../../services/privilege.service";
import {EFE2Features} from "../../../data/account.data";

/* @ngInject */
export default class AdminController {
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public dataService: any;
  public listeners: any;
  public account: any;
  public hasPermission: boolean = true;
  public hasChatFeature: boolean = false;
  public tabIndex: number = -1;

  constructor($rootScope: IRootScopeService, $location: ILocationService, $scope: IScope, $log: ILogService, dataService,
    public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.dataService = dataService;

    let tabIndex = $location.search().param;

    this.listeners = [];
    this.initListeners();

    if (this.dataService.hasAccount()) {
      this.init(tabIndex);
    }
  }

  init(tabIndex: number) {
    this.account = this.dataService.getAccount();
    this.hasChatFeature = this.account.features.includes(EFE2Features.CHAT);
    this.navigateToTab(tabIndex);
  }

  navigateToTab(tabIndex: number) {
    if (tabIndex === -1) {
      this.selectTabForPermission();
    } else {
      if (this.checkTabPermission(tabIndex)) {
        this.tabIndex = tabIndex;
      } else {
        this.selectTabForPermission();
      }
    }
  }

  checkTabPermission(tabIndex: number) {
    switch (tabIndex) {
      case 0:
      case 11:
        return this.privilegeService.has(RolePrivilege.Admin_Overview);
      case 1:
        return this.privilegeService.has(RolePrivilege.Admin_Inputs);
      case 2:
        return this.privilegeService.has(RolePrivilege.Admin_Protocol);
      case 3:
        return this.privilegeService.has(RolePrivilege.Admin_Users);
      case 4:
        return this.privilegeService.has(RolePrivilege.Admin_Relais);
      case 5:
        return this.privilegeService.has(RolePrivilege.Admin_BuildingController);
      case 6:
        return this.privilegeService.has(RolePrivilege.Admin_Logs);
      case 7:
        return this.privilegeService.has(RolePrivilege.Admin_SmsUsage);
      case 8:
        return this.privilegeService.has(RolePrivilege.Admin_PersonShare);
      case 9:
        return this.privilegeService.has(RolePrivilege.Admin_Revision);
      case 10:
        return this.privilegeService.has(RolePrivilege.Admin_Chat) && this.hasChatFeature;
      default:
        return false;
    }
  }

  selectTabForPermission() {
    if (this.privilegeService.has(RolePrivilege.Admin_Overview)) {
      this.tabIndex = 0;
    } else if (this.privilegeService.has(RolePrivilege.Admin_Inputs)) {
      this.tabIndex = 1;
    } else if (this.privilegeService.has(RolePrivilege.Admin_Protocol)) {
      this.tabIndex = 2;
    } else if (this.privilegeService.has(RolePrivilege.Admin_Users)) {
      this.tabIndex = 3;
    } else if (this.privilegeService.has(RolePrivilege.Admin_Relais)) {
      this.tabIndex = 4;
    }else if (this.privilegeService.has(RolePrivilege.Admin_BuildingController)){
      this.tabIndex = 5;
    } else if (this.privilegeService.has(RolePrivilege.Admin_Logs)) {
      this.tabIndex = 6;
    } else if (this.privilegeService.has(RolePrivilege.Admin_SmsUsage)) {
      this.tabIndex = 7;
    } else if (this.privilegeService.has(RolePrivilege.Admin_PersonShare)) {
      this.tabIndex = 8;
    } else if (this.privilegeService.has(RolePrivilege.Admin_Revision)) {
      this.tabIndex = 9;
    } else if (this.privilegeService.has(RolePrivilege.Admin_Chat) && this.hasChatFeature) {
      this.tabIndex = 10;
    } else{
      this.hasPermission = false;
    }
    this.$scope.$applyAsync();

  }

  /**
   * Init event listeners
   */
  initListeners() {
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', () => {
      //Init controller
      this.init(this.tabIndex);
    }));

    this.listeners.push(this.$rootScope.$on('tab.change.admin', (event, data) => {
      if (data === undefined) {
        data = this.tabIndex;
      }
      if (data >= 0) {
        this.navigateToTab(data);
      }
    }));

    this.listeners.push(this.$scope.$watch('ctrl.tabIndex', (newValue: number) => {
      this.$rootScope.$emit('tab.change.admin', newValue);
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}